
import { Widget } from '@typeform/embed-react'
import React, { useState } from 'react';
import styled from "styled-components";
import Modal from 'react-modal';
import Loading from './Loading';

const appElement = document.getElementById('root');

Modal.setAppElement(appElement);


const TypeForm = (
  <div>
    <h1>Stay Updated</h1>
    <Widget id="UaIkjTxh" style={{width: '100%', height: '500px'}}/>
  </div>
);


const modelStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      backgroundColor: '#FFA3FD',
      fontColor: '#191825',
      borderRadius: '4px',
      padding: '20px',
      fontFamily: 'Spline Sans Mono, monospace',
      fontSize: '14px'
    },

  };

const buttonStyle = {
    cursor: 'pointer',
    padding: '20px 40px',
    backgroundColor: '#865DFF',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    fontSize: '20px',
    fontFamily: 'Spline Sans Mono, monospace',
    margin: 'auto 40%'
}

const StayUpdated = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        // Here you can add the function to handle the email address
        const formData = new URLSearchParams();
        formData.append('email', email);
        const response = await fetch('https://empirical-envoy-387602.uc.r.appspot.com/stayinformed?email=' + email, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();

        // handle response data as needed
        setIsLoading(false);
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
      }

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <div>
        <FormStyle>
            <form onSubmit={handleSubmit}>
                <label>
                    <input type="email" value={email} onChange={handleChange} required placeholder='Email: '/>
                </label>
                <button type="submit" alt="Submit"> Stay Informed</button>
            </form>
        </FormStyle>

            <Modal className="informed-model"
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Thanks for staying connected!"
                style={modelStyles}
                >
                <h2>Voila! You're all set. We'll keep your inbox interesting. Stay tuned and keep rocking! </h2>
                <button style={buttonStyle} onClick={handleCloseModal}>Close</button> 
            </Modal>
            {isLoading && <Loading></Loading> }
        </div>


    );
}


const FormStyle = styled.div`
    font-family: 'Spline Sans Mono', monospace;
    margin: 0 30px;

    display: flex;
    flex-direction: column;
    align-items: left;

    input {
        font-family: 'Spline Sans', monospace;
        background: rgba(0,0,0,0.2);
        width: 300px;
        border: 0px;
        color: #E384FF;
        font-size: 16px;
        padding: 12px 30px;
        border: 1px solid #865DFF;
        color: white;
        max-width: 280px;
        background-filter: blur(20px);
        transition: 1s;

        :hover {
            border: 1px solid rgba(255,255,255,0.8);
            tranform: translateY(-3px);
        }
    }
    
    button {
        font-family: 'Spline Sans Mono', monospace;
        background: #191825;
        border: 0px;
        font-size: 16px;
        padding: 12px 30px;
        border-radius: 14px;
        border: 1px solid rgba(255,255,255,0.1);
        color: white;
        max-width: 280px;
        background-filter: blur(20px);
        margin: 30px 70px;

        gap: 12px;

        transition: 1s;

        :hover {
            border: 1px solid rgba(255,255,255,0.8);
            tranform: translateY(-3px);
        }

    }

}
`;



export default StayUpdated;