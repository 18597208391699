import IconTwitter from './images/icon-twitter.svg'
import styled from "styled-components";

export default function Social() {

    return (
        <SocialStyle>
            <a href="https://twitter.com/contentpr0tocol" target="_blank" rel="noopener noreferrer">
                <img src={IconTwitter} alt="Twitter" />
            </a>
        </SocialStyle>

    );
}

const SocialStyle = styled.div`
    top: 20px;
    left: 240px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: right;
    div {
        width: 50%;
        height: 1px;
        background: linear-gradient(
            180deg,
            #086bf9 0%,
            #6c56ef 33.57%,
            #1306dd 65.86%,
            #aa0eb2 100%
        );

    }
`;