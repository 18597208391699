import './App.css';
import styled from "styled-components";
import Menu from './Menu';
import Social from './Social'; 
import StayUpdated from './StayUpdated';

function App() {

  return (
    <div className="App">

      <header className="App-header">
      <Wrapper>
        <Menu></Menu>
        <Content>
        
         
            <h1> Content Protocol </h1>
            <p>Your Creativity Secured! Create Freely, Earn Fairly </p>

            <StayUpdated></StayUpdated>
        </Content>
     </Wrapper>

    
      </header>
    </div>
  );
}

const Wrapper = styled.div`
  font-family: 'Spline Sans', sans-serif;
  font-size: 16px;
  color: white;
  width: 100%;
  height: 100%;

  overflow-x: hidden;

  .spline {
    position: absolute;
    margin: 0;
    top: 0;
    right : 0 ;

    canvas {
        display: block;
    }

    @media (max-wdith: 1024px) {
        transform: scale(0.8) translateX(200px);
        transform-origin: top;
    }
    @media (max-width: 800px){
        transform: scale(0.7) translateX(100px);
    }
    @media (max-width: 600px){
        transform: scale(0.5) translateX(50px);
 
    }
    @media (max-width: 375px){
        transform: scale(0.45) translateX(-20px);
    }
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;


  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  gap: 80px;

  @media (max-width: 1024px) {
    gap: 40px;
  }

  h1 {
    font-family: 'Spline Sans Mono', monospace;
    font-weight: bold;
    font-size: 70px;
    margin: 0;
    max-width: 500px;
    @media (max-width: 1024px) {
        font-size: 60px;
        max-width: 400px;
    }
    @media (max-width: 800px) {
        font-size: 40px;
        max-width: 300px;
    }
    @media (max-width: 600px) {
        padding-top:50px;
    }
  }

  p {
    font-weight: normal;
    line-height: 150%;
    max-width: 380px;
  }

  h1,p {
    margin: 0 30px;
  }
  `;



export default App;
