import Logo from './images/logo.svg'
import styled from "styled-components";
import IconTwitter from './images/icon-twitter.svg'
import Social from './Social';

export default function Menu() {

    return (
        <MenuBar>
            <li><img src={Logo}></img> </li>
            <li> <a href="/">Home</a></li>
            <li> <Social></Social> </li>



        </MenuBar>

    );
}

const MenuBar = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    margin: 0 30px 0 30px;
    padding: 20px;
    li {
        list-style: none;
        margin:0;

        a {
            text-decoration: none;
            color: white;
            padding: 8px 20px;
            border: 1px solid rgba(255,255,255,0);
            transition: 1s;
            borader-radius: 14px;

            :hover {
                border: 1px solid rgba(255,255,255,0.8);
            }

        }
    }
`;