import styled from "styled-components";

export default function Loading() {
    return (
        <LoadingStyle className="spinner">
            
            <div className="blocks">
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                </div>
        </LoadingStyle>
    )
}

const LoadingStyle = styled.div`

.blocks {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.block {
    background-color: #FFA3FD;
    width: 20px;
    height: 20px;
    margin: 3px;
    animation: blocks 1.2s infinite ease-in-out;
}

.block:nth-child(2) {
    animation-delay: -0.4s;
    background-color: #E384FF;
    
}

.block:nth-child(3) {
    animation-delay: -0.8s;
    background-color: #865DFF;
}

.block:nth-child(4) {
    animation-delay: -1.2s;
    background-color: #191825;
}

@keyframes blocks {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
`;

